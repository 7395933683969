import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Gauge from "../Gauge/Gauge";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  ShieldCheckIcon,
  DocumentReportIcon,
  PlusCircleIcon,
  ArchiveIcon,
  RefreshIcon,
  XCircleIcon,
  ChatAlt2Icon,
  EyeIcon,
  CheckCircleIcon,
  UserAddIcon,
  UserRemoveIcon,
  LoginIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const getThisWeekRange = () => {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 7);
  return { startDate, endDate };
};

export default function Reporting() {
  const { startDate, endDate } = getThisWeekRange();

  // Datepicker state
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);

  // Function to handle date range selection
  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setDateRange([selection]);

    // Close date picker only if both dates are selected and are different
    if (
      selection.startDate &&
      selection.endDate &&
      selection.startDate.getTime() !== selection.endDate.getTime()
    ) {
      setShowDatePicker(false);
    }
  };

  // Sample data for the line chart
  const riskData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Operational Critical 1st Party",
        data: [70, 65, 75, 60, 80, 85, 90, 87, 85, 88, 86, 90],
        fill: false,
        borderColor: "rgba(0, 100, 0, 1)",
        tension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Operational Critical 3rd Party",
        data: [50, 55, 65, 48, 70, 72, 75, 74, 73, 76, 78, 80],
        fill: false,
        borderColor: "rgba(0, 255, 0, 1)",
        tension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Non-Operational 1st Party",
        data: [60, 62, 70, 55, 75, 78, 79, 81, 82, 85, 87, 90],
        fill: false,
        borderColor: "rgba(139, 0, 0, 1)",
        tension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Non-Operational 3rd Party",
        data: [45, 52, 60, 50, 65, 68, 70, 72, 73, 75, 78, 80],
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Overall Risk",
        data: [
          56.25, 58.5, 67.5, 53.25, 72.5, 75.75, 78.5, 79.5, 78.25, 81, 82.25,
          85,
        ],
        fill: false,
        borderColor: "rgba(181, 38, 186, 1)",
        tension: 0.5,
        borderWidth: 4,
        borderDash: [5, 5],
        pointRadius: 8,
      },
    ],
  };

  // Options for the line chart
  const riskOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <>
      {/* Logo Section */}
      {/* Logo Section */}
      <section className="mt-4 flex justify-end items-center space-x-4">
        <img src="/dora.webp" alt="DORA" className="h-24 w-auto" />
        <img
          src="/nis2directive.jpeg"
          alt="NIS2 Directive"
          className="h-24 w-auto"
        />
        <img
          src="/nist-csf-20.png"
          alt="NIST CSF 2.0"
          className="h-24 w-auto"
        />
        <img src="/ISO27001.png" alt="ISO 27001" className="h-24 w-auto" />
      </section>

      {/* Header Section */}
      <header className="mt-4 bg-black text-white p-4 flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center justify-center md:justify-start h-16 w-full md:w-auto mb-4 md:mb-0">
          <img
            src="/dark-logo.png"
            alt="Company Logo"
            className="h-full w-auto"
          />
        </div>
        <div className="text-center md:text-right w-full md:w-auto relative">
          <h2 className="text-base md:text-lg font-medium">
            Mobile App Security Compliance Report
          </h2>
          <p
            className="text-xs md:text-sm cursor-pointer text-mobstr-purple hover:underline"
            onClick={() => setShowDatePicker(!showDatePicker)}
          >
            {dateRange[0].startDate.toLocaleDateString()} to{" "}
            {dateRange[0].endDate.toLocaleDateString()}
          </p>
          {showDatePicker && (
            <div className="absolute mt-2 z-10 right-0">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                rangeColors={["#b526ba"]}
              />
            </div>
          )}
          <p className="text-xs md:text-sm">MyCompany Ltd</p>
        </div>
      </header>

      {/* Main Content Section */}
      <section className="mt-4">
        <div className="bg-black text-white p-4 flex justify-between items-start">
          <div className="pr-8">
            <h2 className="text-xl mb-2">Summary</h2>
            <p className="text-sm">
              This is the complete compliance report for your app estate, where
              you can see an audit trail of every action, approval, comment, app
              movement, and details. 
            </p>
          </div>
          <button className="bg-mobstr-purple text-white px-4 py-2 rounded hover:bg-[#3d627a]">
            Download Report
          </button>
        </div>

        <div className="flex flex-col lg:flex-row gap-4 mt-4">
          {/* Left Column: Stacked Sections */}
          <div className="flex-[3] flex flex-col gap-4">
            {/* App Scores */}
            <div className="bg-white p-4 rounded-lg shadow flex-1 flex flex-col">
              <h3 className="font-bold">App Scores</h3>
              <p className="text-sm border-b pb-2">
                The following gauges represent the distribution of apps in your
                estate during the reporting period, categorised by their
                operational importance and type.
              </p>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 mb-4 flex-1">
                <div className="text-center">
                  <p className="font-bold text-sm">
                    Operational Critical <br /> 1st Party
                  </p>
                  <Gauge value="10" />
                </div>
                <div className="text-center">
                  <p className="font-bold text-sm">
                    Operational Critical <br /> 3rd Party
                  </p>
                  <Gauge value="100" />
                </div>
                <div className="text-center">
                  <p className="font-bold text-sm">Non-Operational <br /> 1st Party</p>
                  <Gauge value="50" />
                </div>
                <div className="text-center">
                  <p className="font-bold text-sm">Non-Operational <br /> 3rd Party</p>
                  <Gauge value="75" />
                </div>
              </div>
            </div>
          </div>

          {/* Right Column: Overall Risk Score */}
          <div className="flex-[1.5] space-y-4 flex flex-col">
            {/* Overall Risk Score Box */}
            <div className="bg-white p-4 rounded-lg shadow flex-1 flex flex-col justify-center">
              <h3 className="font-bold text-center">Overall Risk Score</h3>
              <div className="flex flex-col items-center justify-center flex-1">
                <div className="w-1/2">
                  <Gauge />
                </div>
                <div className="text-center mt-4">
                  <p className="text-xl font-bold">+5%</p>
                  <p className="text-sm">vs Previous Period</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Risk Over Time */}
      <section className="mt-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="font-bold">Risk Over Time</h3>
          <div className="mt-4 h-[400px]">
            <Line data={riskData} options={riskOptions} />
          </div>
        </div>
      </section>

      {/* App Movements and Actions */}
      <section className="mt-4">
        <div className="flex flex-col lg:flex-row gap-4 mt-4">
          <div className="flex-[3] flex flex-col gap-4">
            {/* App Movements */}
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="font-bold">App Movements</h3>
              <p className="text-sm border-b pb-2">
                Below is a summary of the changes in app numbers during the
                reporting period
              </p>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                {/* Added */}
                <div className="flex flex-col items-center bg-green-50 p-4 rounded-lg border border-green-200">
                  <PlusCircleIcon className="h-6 w-6 text-green-500 mb-2" />
                  <p className="text-2xl font-bold text-green-600">3</p>
                  <p className="text-sm text-green-600">Added</p>
                </div>

                {/* Archived */}
                <div className="flex flex-col items-center bg-yellow-50 p-4 rounded-lg border border-yellow-200">
                  <ArchiveIcon className="h-6 w-6 text-yellow-500 mb-2" />
                  <p className="text-2xl font-bold text-yellow-600">2</p>
                  <p className="text-sm text-yellow-600">Archived</p>
                </div>

                {/* Updated */}
                <div className="flex flex-col items-center bg-blue-50 p-4 rounded-lg border border-blue-200">
                  <RefreshIcon className="h-6 w-6 text-blue-500 mb-2" />
                  <p className="text-2xl font-bold text-blue-600">9</p>
                  <p className="text-sm text-blue-600">Updated</p>
                </div>

                {/* Rejected */}
                <div className="flex flex-col items-center bg-red-50 p-4 rounded-lg border border-red-200">
                  <XCircleIcon className="h-6 w-6 text-red-500 mb-2" />
                  <p className="text-2xl font-bold text-red-600">1</p>
                  <p className="text-sm text-red-600">Rejected</p>
                </div>
              </div>
            </div>

            {/* Actions */}
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="font-bold">Actions</h3>
              <p className="text-sm border-b pb-2">
                All actions taken on specific risks when reviewing reports
              </p>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                {/* Reviewed */}
                <div className="flex flex-col items-center bg-blue-50 p-4 rounded-lg border border-blue-200">
                  <EyeIcon className="h-6 w-6 text-blue-500 mb-2" />
                  <p className="text-2xl font-bold text-blue-600">3</p>
                  <p className="text-sm text-blue-600">Reviewed</p>
                </div>

                {/* Mitigations */}
                <div className="flex flex-col items-center bg-green-50 p-4 rounded-lg border border-green-200">
                  <ShieldCheckIcon className="h-6 w-6 text-green-500 mb-2" />
                  <p className="text-2xl font-bold text-green-600">7</p>
                  <p className="text-sm text-green-600">Mitigations</p>
                </div>

                {/* Accepted */}
                <div className="flex flex-col items-center bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <CheckCircleIcon className="h-6 w-6 text-gray-500 mb-2" />
                  <p className="text-2xl font-bold text-gray-600">0</p>
                  <p className="text-sm text-gray-600">Accepted</p>
                </div>

                {/* Comments */}
                <div className="flex flex-col items-center bg-purple-50 p-4 rounded-lg border border-purple-200">
                  <ChatAlt2Icon className="h-6 w-6 text-purple-500 mb-2" />
                  <p className="text-2xl font-bold text-purple-600">1</p>
                  <p className="text-sm text-purple-600">Comments</p>
                </div>
              </div>
            </div>

            {/* Other Information */}
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="font-bold mb-4 border-b pb-2">
                Other Information
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                {/* New Users */}
                <div className="flex flex-col items-center bg-blue-50 p-4 rounded-lg border border-blue-200">
                  <UserAddIcon className="h-6 w-6 text-blue-500 mb-2" />
                  <p className="text-2xl font-bold text-blue-600">3</p>
                  <p className="text-sm text-blue-600">New Users</p>
                </div>

                {/* Removed Users */}
                <div className="flex flex-col items-center bg-red-50 p-4 rounded-lg border border-red-200">
                  <UserRemoveIcon className="h-6 w-6 text-red-500 mb-2" />
                  <p className="text-2xl font-bold text-red-600">0</p>
                  <p className="text-sm text-red-600">Removed Users</p>
                </div>

                {/* Logins */}
                <div className="flex flex-col items-center bg-green-50 p-4 rounded-lg border border-green-200">
                  <LoginIcon className="h-6 w-6 text-green-500 mb-2" />
                  <p className="text-2xl font-bold text-green-600">8</p>
                  <p className="text-sm text-green-600">Logins</p>
                </div>

                {/* Failed Logins */}
                <div className="flex flex-col items-center bg-yellow-50 p-4 rounded-lg border border-yellow-200">
                  <ExclamationCircleIcon className="h-6 w-6 text-yellow-500 mb-2" />
                  <p className="text-2xl font-bold text-yellow-600">2</p>
                  <p className="text-sm text-yellow-600">Failed Logins</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-[1.5] space-y-4">
            {/* Mitigations */}
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="font-bold mb-4 border-b pb-2">Mitigations</h3>

              {/* Mitigation 1 */}
              <div className="flex items-start bg-blue-50 border-l-4 border-blue-500 p-4 rounded mb-4">
                <div className="flex-shrink-0">
                  <ShieldCheckIcon className="h-6 w-6 text-blue-500" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-bold">
                    12/6/2024 - CapCut - Video Editor
                  </p>
                  <p className="text-sm">
                    Web Debugging is enabled in WebViews CWE-919 -{" "}
                    <span className="font-semibold text-blue-500">
                      Mitigation:
                    </span>{" "}
                    Webview reviewed - low risk T&C Page - JH
                  </p>
                </div>
              </div>

              {/* Mitigation 2 */}
              <div className="flex items-start bg-green-50 border-l-4 border-green-500 p-4 rounded mb-4">
                <div className="flex-shrink-0">
                  <ShieldCheckIcon className="h-6 w-6 text-green-500" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-bold">
                    13/6/2024 - Zoom - Communication App
                  </p>
                  <p className="text-sm">
                    Unencrypted data transfer detected -{" "}
                    <span className="font-semibold text-green-500">
                      Mitigation:
                    </span>{" "}
                    Encryption enforced for all data transmissions - PR
                  </p>
                </div>
              </div>

              {/* Mitigation 3 */}
              <div className="flex items-start bg-yellow-50 border-l-4 border-yellow-500 p-4 rounded mb-4">
                <div className="flex-shrink-0">
                  <ShieldCheckIcon className="h-6 w-6 text-yellow-500" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-bold">
                    14/6/2024 - Slack - Messaging Platform
                  </p>
                  <p className="text-sm">
                    Outdated SDKs in use -{" "}
                    <span className="font-semibold text-yellow-500">
                      Mitigation:
                    </span>{" "}
                    SDKs updated to latest version for security compliance - LS
                  </p>
                </div>
              </div>

              {/* Mitigation 4 */}
              <div className="flex items-start bg-red-50 border-l-4 border-red-500 p-4 rounded mb-4">
                <div className="flex-shrink-0">
                  <ShieldCheckIcon className="h-6 w-6 text-red-500" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-bold">
                    15/6/2024 - Notion - Productivity Tool
                  </p>
                  <p className="text-sm">
                    Weak password policy -{" "}
                    <span className="font-semibold text-red-500">
                      Mitigation:
                    </span>{" "}
                    Implemented strong password requirements and 2FA - RK
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Detailed Report Section */}
      <section className="mt-4">
        <div className="bg-black text-white p-4 flex justify-between items-center">
          <h2>Detail Report</h2>
        </div>
        <div className="bg-white mt-4 p-4 rounded-lg shadow">
          <h3 className="font-bold mb-4 border-b pb-2">App Movements</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* App 1 */}
            <div className="flex items-center p-4 bg-gray-50 rounded-lg shadow-sm">
              <img
                src="https://play-lh.googleusercontent.com/A7wQb_VdAPEjGpH_8Rl8iRyqNtd0TQzM-pFLQ2fNt2FA6uTaOSpIq707flDXa_pjEyI=s48"
                alt="App Icon"
                className="h-10 w-10"
              />
              <div className="ml-4">
                <p className="font-bold">7 Days Performance</p>
                <p className="text-sm">
                  Added 9/6/2024 | Scored: C | user@mycompany.com (DEPT A)
                </p>
              </div>
            </div>

            {/* App 2 */}
            <div className="flex items-center p-4 bg-gray-50 rounded-lg shadow-sm">
              <img
                src="https://play-lh.googleusercontent.com/6qi3w4uqKaD1c-CBdkkfO6IL0lH4OoCTEdiX0oYbLFxwfvxu1t8vuwHcagdYSFmFKmI=s64"
                alt="App Icon"
                className="h-10 w-10"
              />
              <div className="ml-4">
                <p className="font-bold">ChatGPT</p>
                <p className="text-sm">
                  Added 9/6/2024 | Scored: B | user2@mycompany.com
                </p>
              </div>
            </div>

            {/* App 3 */}
            <div className="flex items-center p-4 bg-gray-50 rounded-lg shadow-sm">
              <img
                src="https://play-lh.googleusercontent.com/hjksnYT_dP3j7nIXXSgXsglMbmSJEA6F30LeZfKvMAjY8HICL3FGJc1I2kI-gQq6FaX0=s64"
                alt="App Icon"
                className="h-10 w-10"
              />
              <div className="ml-4">
                <p className="font-bold">Clock</p>
                <p className="text-sm">Added 9/6/2024 | Scored: A | MDM</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-4 p-4 rounded-lg shadow">
          <h3 className="font-bold mb-4 border-b pb-2">Rejections</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Rejection 1 */}
            <div className="flex flex-col p-4 bg-red-50 rounded-lg shadow-sm">
              <div className="flex items-center">
                <img
                  src="https://play-lh.googleusercontent.com/dyW3i_ezMirgaHiPYFL9CE7qPgisvGky6AeV_i2vIU6p0zxF0mO10yUaT7MViwtjl1E4=w240-h480"
                  alt="Rejection Icon"
                  className="h-10 w-10"
                />
                <div className="ml-4">
                  <p className="font-bold">Temu</p>
                  <p className="text-sm">
                    Rejected 9/6/2024 | Scored: D <br /> Rejected By:
                    admin@mycompany.com
                  </p>
                </div>
              </div>
              {/* Rejection Comment */}
              <div className="mt-2 p-2 bg-white border-l-4 border-red-500 rounded">
                <p className="text-sm">
                  <span className="font-bold">Rejection Comment:</span>{" "}
                  Application rejected due to score and permission scope. User
                  advised and install restricted in MDM - JH
                </p>
              </div>
            </div>

            {/* Rejection 2 */}
            <div className="flex flex-col p-4 bg-red-50 rounded-lg shadow-sm">
              <div className="flex items-center">
                <img
                  src="https://play-lh.googleusercontent.com/KCMTYuiTrKom4Vyf0G4foetVOwhKWzNbHWumV73IXexAIy5TTgZipL52WTt8ICL-oIo=s48"
                  alt="Rejection Icon"
                  className="h-10 w-10"
                />
                <div className="ml-4">
                  <p className="font-bold">Facebook</p>
                  <p className="text-sm">
                    Rejected 9/7/2024 | Scored: F <br /> Rejected By:
                    admin@mycompany.com
                  </p>
                </div>
              </div>
              {/* Rejection Comment */}
              <div className="mt-2 p-2 bg-white border-l-4 border-red-500 rounded">
                <p className="text-sm">
                  <span className="font-bold">Rejection Comment:</span> App XYZ
                  was rejected due to failure in security checks and lack of
                  compliance with privacy policy standards.
                </p>
              </div>
            </div>

            {/* Rejection 3 */}
            <div className="flex flex-col p-4 bg-red-50 rounded-lg shadow-sm">
              <div className="flex items-center">
                <img
                  src="https://play-lh.googleusercontent.com/qXBOdC6WVNPPZso8m5_Z_lCiNTG130ZwHAumXlLpF5r9yaPYICJw9tGGtPt5v9yh35E=w240-h480"
                  alt="Rejection Icon"
                  className="h-10 w-10"
                />
                <div className="ml-4">
                  <p className="font-bold">Windy</p>
                  <p className="text-sm">
                    Rejected 9/8/2024 | Scored: E <br /> Rejected By:
                    admin@mycompany.com
                  </p>
                </div>
              </div>
              {/* Rejection Comment */}
              <div className="mt-2 p-2 bg-white border-l-4 border-red-500 rounded">
                <p className="text-sm">
                  <span className="font-bold">Rejection Comment:</span> App ABC
                  was rejected because it did not adhere to the new data storage
                  guidelines. Please review and resubmit.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-4 p-4 rounded-lg shadow">
          {/* Audit Trail */}
          <h3 className="font-bold mb-4 border-b pb-2">Audit Trail</h3>

          {/* Audit Entry 1 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">9/6/2024 13:22 - Clock</p>
              <p className="text-sm">
                App Added by{" "}
                <span className="font-semibold">user@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 2 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">9/6/2024 13:45 - Temu</p>
              <p className="text-sm">
                App Rejected by{" "}
                <span className="font-semibold">admin@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 3 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">10/6/2024 09:12 - Slack</p>
              <p className="text-sm">
                Permissions updated by{" "}
                <span className="font-semibold">admin@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 4 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">10/6/2024 10:45 - Dropbox</p>
              <p className="text-sm">
                App Archived by{" "}
                <span className="font-semibold">user2@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 5 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">11/6/2024 11:00 - Notion</p>
              <p className="text-sm">
                App Reviewed by{" "}
                <span className="font-semibold">reviewer@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 6 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">12/6/2024 15:30 - Zoom</p>
              <p className="text-sm">
                Security settings updated by{" "}
                <span className="font-semibold">admin@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 7 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">12/6/2024 16:00 - CapCut</p>
              <p className="text-sm">
                App Mitigation actions recorded by{" "}
                <span className="font-semibold">security@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 8 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">13/6/2024 14:22 - Clock</p>
              <p className="text-sm">
                App Updated by{" "}
                <span className="font-semibold">user@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 9 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded mb-4">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">14/6/2024 09:15 - App XYZ</p>
              <p className="text-sm">
                Compliance check completed by{" "}
                <span className="font-semibold">compliance@mycompany.com</span>
              </p>
            </div>
          </div>

          {/* Audit Entry 10 */}
          <div className="flex items-start bg-gray-50 border-l-4 border-gray-300 p-4 rounded">
            <div className="flex-shrink-0">
              <DocumentReportIcon className="h-6 w-6 text-gray-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-bold">15/6/2024 12:10 - App ABC</p>
              <p className="text-sm">
                App Resubmitted by{" "}
                <span className="font-semibold">developer@mycompany.com</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
