import { getGrade } from "../../utils";
import TimeAgo from "react-timeago";

function LatestResults({ appStats }) {

  const getColour = score => {
    let grade = getGrade(score); 
    switch (grade) {
      case "A*":
        return "bg-green-600";
      case "A":
        return "bg-green-600";
      case "B":
        return "bg-green-600";
      case "C":
        return "bg-yellow-400";
      case "D":
        return "bg-red-600";
      default:
        return "bg-red-600";

    }
  }
  return (
    <ul className="max-w-md divide-y divide-gray-200">
      { appStats.latest1 && <li className="pb-3 sm:pb-4">
        <div className="flex items-center space-x-4 rtl:space-x-reverse">
          <div className="flex-shrink-0">
            <img
              className="w-8 h-8 rounded-md"
              src={appStats.latest1?.app.icon}
              alt={appStats.latest1?.app.name}
            />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900">
              {appStats.latest1?.app.name}
            </p>
            <p className="text-xs text-gray-500">
              <TimeAgo date={appStats.latest1?.app.updatedAt} minPeriod={60} />
            </p>
          </div>
          <div className={`flex items-center justify-center w-8 h-8  rounded-full font-semibold text-white ${getColour(appStats.latest1?.app.score)}`}>
            {getGrade(appStats.latest1?.app.score)}
          </div>
        </div>
      </li>}
      { appStats.latest2 && <li className="py-3 sm:py-4">
        <div className="flex items-center space-x-4 rtl:space-x-reverse">
          <div className="flex-shrink-0">
            <img
              className="w-8 h-8 rounded-md"
              src={appStats.latest2?.app.icon}
              alt={appStats.latest2?.app.name}
            />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900">
              {appStats.latest2?.app.name}
            </p>
            <p className="text-xs text-gray-500">
              <TimeAgo date={appStats.latest2?.app.updatedAt} minPeriod={60} />
            </p>
          </div>
          <div className={`flex items-center justify-center w-8 h-8  rounded-full font-semibold text-white ${getColour(appStats.latest2?.app.score)}`}>
            {getGrade(appStats.latest2?.app.score)}
          </div>
        </div>
      </li> }
      { appStats.latest3 && <li className="py-3 sm:py-4">
        <div className="flex items-center space-x-4 rtl:space-x-reverse">
          <div className="flex-shrink-0">
            <img
              className="w-8 h-8 rounded-md"
              src={appStats.latest3?.app.icon}
              alt={appStats.latest3?.app.name}
            />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm text-gray-900">{appStats.latest3?.app.name}</p>
            <p className="text-xs text-gray-500">
              <TimeAgo date={appStats.latest3?.app.updatedAt} minPeriod={60} />
            </p>
          </div>
          <div className={`flex items-center justify-center w-8 h-8  rounded-full font-semibold text-white ${getColour(appStats.latest3?.app.score)}`}>
            {getGrade(appStats.latest3?.app.score)}
          </div>
        </div>
      </li> }

      { appStats.latest4 && <li className="py-3 sm:py-4">
        <div className="flex items-center space-x-4 rtl:space-x-reverse">
          <div className="flex-shrink-0">
            <img
              className="w-8 h-8 rounded-md"
              src={appStats.latest4?.app.icon}
              alt={appStats.latest4?.app.name}
            />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm text-gray-900">{appStats.latest4?.app.name}</p>
            <p className="text-xs text-gray-500">
              <TimeAgo date={appStats.latest4?.app.updatedAt} minPeriod={60} />
            </p>
          </div>
          <div className={`flex items-center justify-center w-8 h-8  rounded-full font-semibold text-white ${getColour(appStats.latest4?.app.score)}`}>
            {getGrade(appStats.latest4?.app.score)}
          </div>
        </div>
      </li> }

      { appStats.latest5 && <li className="py-3 sm:py-4">
        <div className="flex items-center space-x-4 rtl:space-x-reverse">
          <div className="flex-shrink-0">
            <img
              className="w-8 h-8 rounded-md"
              src={appStats.latest5?.app.icon}
              alt={appStats.latest5?.app.name}
            />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm text-gray-900">{appStats.latest5?.app.name}</p>
            <p className="text-xs text-gray-500">
              <TimeAgo date={appStats.latest5?.app.updatedAt} minPeriod={60} />
            </p>
          </div>
          <div className={`flex items-center justify-center w-8 h-8  rounded-full font-semibold text-white ${getColour(appStats.latest5?.app.score)}`}>
            {getGrade(appStats.latest5?.app.score)}
          </div>
        </div>
      </li> }
    </ul>
  );
}

export default LatestResults;
